import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './contact.css'

const Contact = (props) => {
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - Nocturio</title>
        <meta
          name="description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
        <meta property="og:title" content="Contact - Nocturio" />
        <meta
          property="og:description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
      </Helmet>
      <div className="contact-header">
        <header
          data-thq="thq-navbar"
          className="contact-navbar-interactive navbarContainer"
        >
          <Link to="/">
            <div className="contact-logo">
              <span className="contact-logo1">Nocturio</span>
              <img
                alt="image"
                src="/external/fiendsyneye_2-600h.png"
                className="contact-image"
              />
            </div>
          </Link>
          <div data-thq="thq-navbar-nav" className="contact-desktop-menu">
            <nav className="contact-links">
              <a href="https://noctur.io/" className="contact-nav100">
                Home
              </a>
              <a
                href="https://noctur.io/about"
                className="contact-nav12 bodySmall"
              >
                About
              </a>
              <a
                href="https://noctur.io/blog"
                className="contact-nav22 bodySmall"
              >
                Blog
              </a>
              <span className="contact-nav42 bodySmall">Crowdfunding</span>
              <a href="https://noctur.io/contact" className="contact-nav62">
                Contact
              </a>
            </nav>
          </div>
          <div className="contact-container01">
            <nav className="contact-container02">
              <Link to="/about" className="contact-nav-ab bodySmall">
                About |
              </Link>
              <Link to="/blog" className="contact-nav-blog bodySmall">
                Blog |
              </Link>
              <span className="contact-nav-fund bodySmall">Funding</span>
              <span className="contact-nav-contact bodySmall">| Contact</span>
            </nav>
            <div
              data-thq="thq-burger-menu"
              className="contact-burger-menu"
            ></div>
          </div>
          <div className="contact-container03">
            <div
              data-thq="thq-mobile-menu"
              className="contact-mobile-menu1 mobileMenu"
            ></div>
          </div>
        </header>
      </div>
      <div className="contact-contact">
        <div className="contact-contact-container featuresContainer">
          <div className="contact-contact1">
            <h2 className="contact-features-heading onLoad">Need Some Help?</h2>
            <form
              id="ContactForm"
              name="contact-form"
              action="https://formsubmit.co/760105d53e2ecd41708c68cb8786e1b1"
              method="POST"
              enctype="application/x-www-form-urlencoded"
              autoComplete="email"
              className="contact-form onLoad2"
            >
              <div className="contact-form-container">
                <div className="contact-container04">
                  <img
                    alt="image"
                    src="/external/fiendsyneye_2-600h.png"
                    className="contact-image1"
                  />
                  <span className="contact-text">
                    <span className="contact-text01">Contact Us</span>
                    <br></br>
                  </span>
                  <h1 className="contact-text03">
                    <span className="contact-text04">
                      For official inquires please fill out the contact form
                      below.
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <br className="contact-text05"></br>
                    <span className="contact-text06">
                      For all other inquiries see the contact information
                      section. Thank you!
                    </span>
                    <br></br>
                  </h1>
                </div>
                <div className="contact-input">
                  <input
                    type="text"
                    id="other"
                    name="other"
                    placeholder="Other"
                    autoComplete="off"
                    className="contact-textinput input"
                  />
                  <input
                    type="text"
                    id="family-name"
                    name="family-name"
                    required="true"
                    placeholder="Last Name*"
                    autoComplete="off"
                    className="contact-last-name input"
                  />
                  <input
                    type="text"
                    id="given-name"
                    name="given-name"
                    color="#000000"
                    required="true"
                    placeholder="First Name*"
                    autoComplete="off"
                    className="contact-name input"
                  />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required="true"
                    placeholder="Email*"
                    autoComplete="off"
                    className="contact-email input"
                  />
                  <input
                    type="text"
                    id="organization"
                    name="organization"
                    required="true"
                    placeholder="Organization*"
                    autoComplete="off"
                    className="contact-org input"
                  />
                  <input
                    type="tel"
                    id="tel"
                    name="tel"
                    required="true"
                    placeholder="Phone Number*"
                    autoComplete="off"
                    className="contact-phone input"
                  />
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    rows="Subject*"
                    required="true"
                    placeholder="Subject*"
                    autoComplete="off"
                    className="contact-subject input"
                  />
                  <textarea
                    id="message"
                    name="message"
                    type="message"
                    placeholder="Your Message*"
                    autoComplete="off"
                    className="contact-textarea textarea"
                  ></textarea>
                  <button
                    name="Submit"
                    type="submit"
                    className="contact-submit button buttonFilled"
                  >
                    <span className="contact-text08">
                      <span>Submit</span>
                      <br></br>
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <div className="contact-container05">
              <div className="contact-container06 onLoad3">
                <div className="contact-container07">
                  <h1 className="contact-text11">
                    <span>General Inquiries</span>
                    <br></br>
                  </h1>
                </div>
                <span className="contact-text14">
                  <span className="contact-text15">
                    For general inquires or support please feel free to contact
                    us at:
                  </span>
                  <br className="contact-text16"></br>
                  <br className="contact-text17"></br>
                  <br className="contact-text18"></br>
                  <span className="contact-text19">Email:</span>
                  <span className="contact-text20"> contact@noctur.io</span>
                  <br className="contact-text21"></br>
                  <br className="contact-text22"></br>
                  <span className="contact-text23">TikTok:</span>
                  <span className="contact-text24"> @_nocturio</span>
                  <br className="contact-text25"></br>
                  <br className="contact-text26"></br>
                  <span className="contact-text27">
                    X (formerly twitter):
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className="contact-text28">@_nocturio</span>
                  <br className="contact-text29"></br>
                  <br className="contact-text30"></br>
                  <span className="contact-text31">Instagram:</span>
                  <span className="contact-text32"> @_nocturio</span>
                  <br className="contact-text33"></br>
                  <br className="contact-text34"></br>
                  <br className="contact-text35"></br>
                  <span className="contact-text36">
                    Response times may vary.
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br className="contact-text37"></br>
                  <br className="contact-text38"></br>
                  <br className="contact-text39"></br>
                  <span className="contact-text40">
                    Support hours: Monday- Friday
                  </span>
                  <br className="contact-text41"></br>
                  <span className="contact-text42">
                    7am - 6pm (Mountain Standard Time)
                  </span>
                  <br className="contact-text43"></br>
                  <br className="contact-text44"></br>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-footer">
        <footer className="contact-footer1 footerContainer">
          <div className="contact-container08">
            <div className="contact-container09">
              <span className="contact-logo2">Nocturio</span>
              <img
                alt="image"
                src="/external/fiendsyneye_2-600h.png"
                className="contact-image2"
              />
            </div>
            <nav className="contact-nav">
              <span className="contact-nav121 bodySmall">About |</span>
              <span className="contact-nav221 bodySmall">Blog |</span>
              <span className="contact-nav421 bodySmall">Funding |</span>
              <span className="contact-nav621 bodySmall">Contact</span>
            </nav>
          </div>
          <div className="contact-separator"></div>
          <div className="contact-container10">
            <span className="contact-text45 bodySmall">
              © 2024 Nocturio, All Rights Reserved.
            </span>
            <div className="contact-icon-group">
              <a
                href="https://twitter.com/_nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="contact-link"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="contact-icon socialIcons"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/_nocturio/"
                target="_blank"
                rel="noreferrer noopener"
                className="contact-link1"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="contact-icon2 socialIcons"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="contact-link2"
              >
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="contact-icon4 socialIcons"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Contact
