import React from 'react'

import { Helmet } from 'react-helmet'

import Question1 from '../components/question1'
import './about.css'

const About = (props) => {
  return (
    <div className="about-container">
      <Helmet>
        <title>About - Nocturio</title>
        <meta
          name="description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
        <meta property="og:title" content="About - Nocturio" />
        <meta
          property="og:description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
      </Helmet>
      <div className="about-header">
        <header
          data-thq="thq-navbar"
          className="about-navbar-interactive navbarContainer"
        >
          <div className="about-logo">
            <a href="https://noctur.io/" className="about-logo1">
              Nocturio
            </a>
            <img
              alt="image"
              src="/external/fiendsyneye_2-200w.png"
              className="about-image"
            />
          </div>
          <div data-thq="thq-navbar-nav" className="about-desktop-menu">
            <nav className="about-links">
              <a href="https://noctur.io/" className="about-nav100">
                Home
              </a>
              <a href="https://noctur.io/about" className="about-nav12">
                About
              </a>
              <a
                href="https://noctur.io/blog"
                className="about-nav22 bodySmall"
              >
                Blog
              </a>
              <span className="about-nav42 bodySmall">Crowdfunding</span>
              <a
                href="https://noctur.io/contact"
                className="about-nav62 bodySmall"
              >
                Contact
              </a>
            </nav>
          </div>
          <div className="about-container01">
            <nav className="about-container02">
              <a
                href="https://noctur.io/about"
                className="about-nav-ab bodySmall"
              >
                About |
              </a>
              <a
                href="https://noctur.io/blog"
                className="about-nav-blog bodySmall"
              >
                Blog |
              </a>
              <span className="about-nav-fund bodySmall">Funding</span>
              <a
                href="https://noctur.io/contact"
                className="about-nav-contact bodySmall"
              >
                | Contact
              </a>
            </nav>
            <div data-thq="thq-burger-menu" className="about-burger-menu"></div>
          </div>
          <div className="about-container03">
            <div
              data-thq="thq-mobile-menu"
              className="about-mobile-menu1 mobileMenu"
            ></div>
          </div>
          <button type="button" className="about-button buttonFilled">
            <span>
              <span>CONTRIBUTE &amp; GET ACCESS</span>
              <br></br>
            </span>
          </button>
        </header>
      </div>
      <div className="about-hero">
        <div className="about-hero1 heroContainer">
          <div className="about-container04">
            <h1 className="about-hero-heading onLoad">
              Empowering Creators, Inspiring Readers.
            </h1>
            <span className="about-sub-heading onLoad2">
              Bringing people together through stories.
            </span>
          </div>
        </div>
        <div className="about-features-container featuresContainer">
          <div className="about-features onLoad3">
            <div className="about-container05">
              <h2 className="about-features-heading heading2">
                What is Nocturio?
              </h2>
            </div>
            <div className="about-container06">
              <span className="about-text3">
                Nocturio is a dynamic platform uniting creators from various
                disciplines. Our mission is to streamline the process of
                storytelling, audiobook production, illustration, and beyond,
                connecting creators with a diverse audience. By offering
                editorial support, we accelerate content release, ensuring fans
                can enjoy fresh works sooner. We value the collaborative spirit
                of content creation, fostering connections among writers,
                voice-over artists, and illustrators. In response to community
                feedback, we&apos;re committed to expanding our roster of
                creator types in the future. We deeply appreciate your ongoing
                support and candid feedback as we continue to grow and evolve.
              </span>
            </div>
          </div>
        </div>
        <div className="about-banner">
          <div className="about-banner1 bannerContainer onScroll">
            <div className="about-container07">
              <h1 className="about-banner-heading heading2">Who we are.</h1>
            </div>
            <div className="about-container08">
              <span className="about-text4">
                At Nocturio, we&apos;re a dedicated team passionate about
                nurturing creativity and collaboration. We provide a platform
                where creators from diverse backgrounds unite to bring their
                visions to life. Our focus is on supporting creators throughout
                the creative process, accelerating content production, and
                fostering connections with audiences. With inclusivity at our
                core, we strive to create a welcoming space for creators of all
                kinds. Together, we&apos;re building a vibrant community where
                every voice matters.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="about-faq">
        <div className="about-faq-container faqContainer">
          <div className="about-faq1">
            <div className="about-container09">
              <h2 className="about-text5 heading2 onScroll">FAQ</h2>
            </div>
            <div className="about-container10">
              <div className="about-container11">
                <Question1
                  answer="Nocturio is a platform that connects users and creators while also providing many useful tools and resources to creators with the goal of creating a great experience for both groups. "
                  question="What is Nocturio?"
                  rootClassName="question1-root-class-name12"
                ></Question1>
                <Question1
                  answer="You can support us by contributing to our crowdfunding campaigns and following us on our socials! We will also soon be adding early access for creators so we can begin to populate the platform with content."
                  question="How can I contribute to the app's development?"
                  rootClassName="question1-root-class-name13"
                ></Question1>
                <Question1
                  answer="Yes, we welcome submissions from indie authors as well as voice actors. Stay tuned for submission guidelines on our website."
                  question="Can I submit my own writing to be featured on the app?"
                  rootClassName="question1-root-class-name14"
                ></Question1>
                <Question1
                  answer="We aim to provide regular updates on our development progress, typically on a weekly basis."
                  question="How often will the development blog be updated?"
                  rootClassName="question1-root-class-name15"
                ></Question1>
                <Question1
                  answer="Stay updated! Follow us on socials or subscribe to our newsletter for the latest news. Join our mailing list for exclusive updates!"
                  question="How can I stay updated on app news and announcements?"
                  rootClassName="question1-root-class-name1"
                ></Question1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-footer">
        <footer className="about-footer1 footerContainer">
          <div className="about-container12">
            <div className="about-container13">
              <span className="about-logo2">Nocturio</span>
              <img
                alt="image"
                src="/external/fiendsyneye_2-200w.png"
                className="about-image1"
              />
            </div>
            <nav className="about-nav">
              <span className="about-nav121 bodySmall">About |</span>
              <span className="about-nav221 bodySmall">Blog |</span>
              <span className="about-nav421 bodySmall">Funding |</span>
              <span className="about-nav621 bodySmall">Contact</span>
            </nav>
          </div>
          <div className="about-separator"></div>
          <div className="about-container14">
            <span className="about-text6 bodySmall">
              © 2024 Nocturio, All Rights Reserved.
            </span>
            <div className="about-icon-group">
              <a
                href="https://twitter.com/_nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="about-icon socialIcons"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/_nocturio/"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link1"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="about-icon2 socialIcons"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="about-link2"
              >
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="about-icon4 socialIcons"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default About
