import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import GalleryCard2 from '../components/gallery-card2'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Nocturio</title>
        <meta
          name="description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
        <meta property="og:title" content="Nocturio" />
        <meta
          property="og:description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
      </Helmet>
      <div className="home-header">
        <header
          data-thq="thq-navbar"
          className="home-navbar-interactive navbarContainer"
        >
          <Link to="/">
            <div className="home-logo">
              <span className="home-logo1">Nocturio</span>
              <img
                alt="image"
                src="/external/fiendsyneye_2-200w.png"
                className="home-image"
              />
            </div>
          </Link>
          <nav className="home-links">
            <a
              href="https://noctur.io/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-nav100"
            >
              Home
            </a>
            <a href="https://noctur.io/about" className="home-nav12">
              About
            </a>
            <a href="https://noctur.io/blog" className="home-nav22">
              Blog
            </a>
            <span className="home-nav42">Crowdfunding</span>
            <a href="https://noctur.io/contact" className="home-nav62">
              Contact
            </a>
          </nav>
          <div data-thq="thq-navbar-nav" className="home-desktop-menu"></div>
          <div className="home-container01">
            <nav className="home-container02">
              <a
                href="https://noctur.io/about"
                className="home-nav-ab bodySmall"
              >
                About |
              </a>
              <a
                href="https://noctur.io/blog"
                className="home-nav-blog bodySmall"
              >
                Blog |
              </a>
              <span className="home-nav-fund bodySmall">Funding</span>
              <a
                href="https://noctur.io/contact"
                className="home-nav-contact bodySmall"
              >
                | Contact
              </a>
            </nav>
            <div data-thq="thq-burger-menu" className="home-burger-menu"></div>
          </div>
          <div className="home-container03">
            <div className="home-container04">
              <div
                data-thq="thq-mobile-menu"
                className="home-mobile-menu1 mobileMenu"
              ></div>
            </div>
            <button type="button" className="home-button buttonFilled">
              <span>
                <span>CONTRIBUTE &amp; GET ACCESS</span>
                <br></br>
              </span>
            </button>
          </div>
        </header>
      </div>
      <div className="home-hero">
        <div className="home-hero1 heroContainer">
          <div className="home-container05">
            <h1 className="home-hero-heading onLoad">
              Discover the Next Chapter
            </h1>
            <span className="home-sub-heading onLoad2">
              We&apos;re glad you&apos;re here.
            </span>
            <div className="home-btn-group">
              <a
                href="https://forms.gle/H9foogQsEC9wpMPf6"
                target="_blank"
                rel="noreferrer noopener"
                className="home-hero-button1 onLoad3 buttonFilled"
              >
                CREATE YOUR OWN FREEDOM
              </a>
              <a
                href="https://forms.gle/H9foogQsEC9wpMPf6"
                target="_blank"
                rel="noreferrer noopener"
                className="home-hero-button2 onLoad3 buttonFlat"
              >
                Subscribe to our Newsletter→
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="home-features">
        <div className="home-features-container featuresContainer">
          <div className="home-features1">
            <div className="home-container06">
              <h2 className="home-features-heading onScroll">
                Explore the our Unique Applications
              </h2>
              <span className="home-features-sub-heading onScroll">
                Explore the unique features of our must have applications
              </span>
            </div>
            <div className="home-gallery">
              <div className="home-container07">
                <div className="home-container08">
                  <div className="home-container09 onScroll">
                    <GalleryCard2
                      title="Monetization"
                      imageSrc="https://firebasestorage.googleapis.com/v0/b/nocturio-ae9f9.appspot.com/o/public-assets%2FFigma_ovJcH8WY1Q.gif?alt=media&amp;token=bc84c172-b8bf-4cdd-9640-60e26d1873e3"
                      description="We offer a variety of monetization options for creators that join the Creators Circle."
                      rootClassName="rootClassName2"
                    ></GalleryCard2>
                  </div>
                  <div className="home-container10 onScroll">
                    <GalleryCard2
                      title="Advanced Editing Tools"
                      imageSrc="https://firebasestorage.googleapis.com/v0/b/nocturio-ae9f9.appspot.com/o/public-assets%2FDSC_0036-resize.jpg?alt=media&amp;token=22f1588a-b649-4fac-b160-b1bc95a75c8d"
                      description="Nocturio equips creators with integrated, sophisticated editing tools directly within the platform, enhancing the quality of their work and ensuring that each piece is polished and professional."
                      rootClassName="rootClassName3"
                    ></GalleryCard2>
                  </div>
                </div>
                <div className="home-container11 onScroll">
                  <GalleryCard2
                    title="Library"
                    imageSrc="https://firebasestorage.googleapis.com/v0/b/nocturio-ae9f9.appspot.com/o/public-assets%2FDSC_0013.JPG?alt=media&amp;token=08a887e8-870b-418a-82a9-d105e403b45b"
                    description="Rapidly growing collection of content of all types. From comic adaptations of indie books to user-made voice overs of classics."
                    rootClassName="rootClassName"
                  ></GalleryCard2>
                </div>
              </div>
              <div className="home-container12 onScroll">
                <GalleryCard2
                  title="Entertainment your way."
                  imageSrc="https://firebasestorage.googleapis.com/v0/b/nocturio-ae9f9.appspot.com/o/public-assets%2FCollage.png?alt=media&amp;token=128ce721-310c-467f-bf47-6075f48e8f6d"
                  description="Experience content exactly as you envision it."
                  rootClassName="rootClassName4"
                ></GalleryCard2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-banner">
        <div className="home-banner1 bannerContainer">
          <h1 className="home-banner-heading heading2 onScroll">
            Unleash Your Imagination
          </h1>
          <span className="home-text03 onScroll">
            Our platforms are for emerging creators to showcase their work and
            for users to explore diverse creations. Join us on this journey!
          </span>
          <button className="home-banner-button onScroll buttonFilled">
            Learn More
          </button>
        </div>
      </div>
      <div className="home-updates">
        <div className="home-blog-container faqContainer">
          <div className="home-blog-updates">
            <div className="home-container13">
              <h2 className="home-text04">
                <span className="home-text05 heading2">Most Recent Update</span>
                <br></br>
              </h2>
            </div>
            <div className="home-container14">
              <Link to="/intro-article" className="home-navlink1">
                <div className="home-intro-card onScroll">
                  <img
                    alt="image"
                    src="https://firebasestorage.googleapis.com/v0/b/nocturio-ae9f9.appspot.com/o/public-assets%2Fmask.jpg?alt=media&amp;token=df11f3cb-92e6-44be-9fc4-fdf950d4a982"
                    loading="eager"
                    className="home-image1"
                  />
                  <div className="home-container15">
                    <span className="home-text07">Introducing nocturio</span>
                    <span className="home-text08">
                      <span>&quot;Nocturne + .io&quot;</span>
                      <br></br>
                    </span>
                    <span className="home-text11">
                      Our first blog post! Learn a little bit about us and how
                      you can support us on this amazing journey! We&apos;ll be
                      covering insights into the Development process and how you
                      can get in contact with us for questions or press
                      inquiries. At the end of the post, you can find our first
                      revision of the privacy policy and terms and conditions
                      plus our press kit.
                    </span>
                    <div className="home-container16">
                      <div className="home-profile">
                        <img
                          alt="profile"
                          src="/external/20220305_152758-200h.jpg"
                          loading="eager"
                          image_src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&amp;ixlib=rb-1.2.1&amp;h=1200"
                          className="home-image2"
                        />
                        <span className="home-text12">Cameron Emery, CEO</span>
                      </div>
                      <span className="home-text13">10 min read</span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="home-footer">
        <footer className="home-footer1 footerContainer">
          <div className="home-container17">
            <div className="home-container18">
              <span className="home-logo2">Nocturio</span>
              <img
                alt="image"
                src="/external/fiendsyneye_2-200w.png"
                className="home-image3"
              />
            </div>
            <nav className="home-nav">
              <a
                href="https://noctur.io/about"
                target="_blank"
                rel="noreferrer noopener"
                className="home-nav121 bodySmall"
              >
                About |
              </a>
              <a
                href="https://noctur.io/blog"
                target="_blank"
                rel="noreferrer noopener"
                className="home-nav221 bodySmall"
              >
                Blog |
              </a>
              <a
                href="https://noctur.io/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-nav421 bodySmall"
              >
                Funding |
              </a>
              <a
                href="https://noctur.io/contact"
                target="_blank"
                rel="noreferrer noopener"
                className="home-nav621 bodySmall"
              >
                Contact
              </a>
            </nav>
          </div>
          <div className="home-separator"></div>
          <div className="home-container19">
            <span className="home-text14 bodySmall">
              © 2024 Nocturio, All Rights Reserved.
            </span>
            <div className="home-icon-group">
              <a
                href="https://twitter.com/_nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="home-icon socialIcons"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/_nocturio/"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="home-icon2 socialIcons"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link2"
              >
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="home-icon4 socialIcons"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
