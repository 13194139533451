import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './blog.css'

const Blog = (props) => {
  return (
    <div className="blog-container">
      <Helmet>
        <title>Blog - Nocturio</title>
        <meta
          name="description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
        <meta property="og:title" content="Blog - Nocturio" />
        <meta
          property="og:description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
      </Helmet>
      <div className="blog-header">
        <header
          data-thq="thq-navbar"
          className="blog-navbar-interactive navbarContainer"
        >
          <Link to="/">
            <div className="blog-logo">
              <span className="blog-logo1">Nocturio</span>
              <img
                alt="image"
                src="/external/fiendsyneye_2-200w.png"
                className="blog-image"
              />
            </div>
          </Link>
          <div data-thq="thq-navbar-nav" className="blog-desktop-menu">
            <nav className="blog-links">
              <a href="https://noctur.io/" className="blog-nav100">
                Home
              </a>
              <a href="https://noctur.io/about" className="blog-nav12">
                About
              </a>
              <a href="https://noctur.io/blog" className="blog-nav22">
                Blog
              </a>
              <span className="blog-nav42 bodySmall">Crowdfunding</span>
              <a
                href="https://noctur.io/contact"
                className="blog-nav62 bodySmall"
              >
                Contact
              </a>
            </nav>
          </div>
          <div className="blog-container01">
            <nav className="blog-container02">
              <Link to="/about" className="blog-nav-ab bodySmall">
                About |
              </Link>
              <Link to="/blog" className="blog-nav-blog bodySmall">
                Blog |
              </Link>
              <span className="blog-nav-fund bodySmall">Funding</span>
              <span className="blog-nav-contact bodySmall">| Contact</span>
            </nav>
            <div data-thq="thq-burger-menu" className="blog-burger-menu"></div>
          </div>
          <div className="blog-container03">
            <div
              data-thq="thq-mobile-menu"
              className="blog-mobile-menu1 mobileMenu"
            ></div>
          </div>
          <button type="button" className="blog-button buttonFilled">
            <span>
              <span>CONTRIBUTE &amp; GET ACCESS</span>
              <br></br>
            </span>
          </button>
        </header>
      </div>
      <div className="blog-hero">
        <div className="blog-hero1 heroContainer">
          <div className="blog-container04">
            <h1 className="blog-hero-heading heading1">Our Dev Journey</h1>
            <span className="blog-features-sub-heading bodyLarge">
              <span>
                Explore the unique features that make our app a must-have for
                literature enthusiasts.
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="blog-blog">
        <div className="blog-blog-container featuresContainer">
          <div className="blog-container05">
            <h2 className="blog-features-heading heading2">Latest Update</h2>
          </div>
          <div className="blog-blog-posts">
            <Link to="/intro-article" className="blog-navlink1">
              <div className="blog-intro-card">
                <img
                  alt="image"
                  src="https://firebasestorage.googleapis.com/v0/b/nocturio-ae9f9.appspot.com/o/public-assets%2Fmask.jpg?alt=media&amp;token=df11f3cb-92e6-44be-9fc4-fdf950d4a982"
                  loading="eager"
                  className="blog-image1"
                />
                <div className="blog-container06">
                  <span className="blog-text11">Introducing nocturio</span>
                  <span className="blog-text12">
                    <span>&quot;Nocturne + .io&quot;</span>
                    <br></br>
                  </span>
                  <span className="blog-text15">
                    Our first blog post! Learn a little bit about us and how you
                    can support us on this amazing journey! We&apos;ll be
                    covering insights into the Development process and how you
                    can get in contact with us for questions or press inquiries.
                    At the end of the post, you can find our first revision of
                    the privacy policy and terms and conditions plus our press
                    kit.
                  </span>
                  <div className="blog-container07">
                    <div className="blog-profile">
                      <img
                        alt="profile"
                        src="/external/20220305_152758-200h.jpg"
                        loading="eager"
                        image_src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&amp;ixlib=rb-1.2.1&amp;h=1200"
                        className="blog-image2"
                      />
                      <span className="blog-text16">Cameron Emery, CEO</span>
                    </div>
                    <span className="blog-text17">10 min read</span>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="blog-footer">
        <footer className="blog-footer1 footerContainer">
          <div className="blog-container08">
            <div className="blog-container09">
              <span className="blog-logo2">Nocturio</span>
              <img
                alt="image"
                src="/external/fiendsyneye_2-200w.png"
                className="blog-image3"
              />
            </div>
            <nav className="blog-nav">
              <span className="blog-nav121 bodySmall">About |</span>
              <span className="blog-nav221 bodySmall">Blog |</span>
              <span className="blog-nav421 bodySmall">Funding |</span>
              <span className="blog-nav621 bodySmall">Contact</span>
            </nav>
          </div>
          <div className="blog-separator"></div>
          <div className="blog-container10">
            <span className="blog-text18 bodySmall">
              © 2024 Nocturio, All Rights Reserved.
            </span>
            <div className="blog-icon-group">
              <a
                href="https://twitter.com/_nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="blog-link"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="blog-icon socialIcons"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/_nocturio/"
                target="_blank"
                rel="noreferrer noopener"
                className="blog-link1"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="blog-icon2 socialIcons"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="blog-link2"
              >
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="blog-icon4 socialIcons"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Blog
