import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import './intro-article.css'

const IntroArticle = (props) => {
  return (
    <div className="intro-article-container">
      <Helmet>
        <title>Intro-Article - Nocturio</title>
        <meta
          name="description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
        <meta property="og:title" content="Intro-Article - Nocturio" />
        <meta
          property="og:description"
          content="Join Nocturio, the hub for creators in storytelling, audiobooks, and illustration. We expedite content release, support emerging talents, and foster diverse collaborations."
        />
      </Helmet>
      <div className="intro-article-header">
        <header
          data-thq="thq-navbar"
          className="intro-article-navbar-interactive navbarContainer"
        >
          <Link to="/">
            <div className="intro-article-logo">
              <span className="intro-article-logo1">Nocturio</span>
              <img
                alt="image"
                src="/external/fiendsyneye_2-200w.png"
                className="intro-article-image"
              />
            </div>
          </Link>
          <div data-thq="thq-navbar-nav" className="intro-article-desktop-menu">
            <nav className="intro-article-links">
              <a href="https://noctur.io/" className="intro-article-nav100">
                Home
              </a>
              <a
                href="https://noctur.io/about"
                className="intro-article-nav12 bodySmall"
              >
                About
              </a>
              <a href="https://noctur.io/blog" className="intro-article-nav22">
                Blog
              </a>
              <span className="intro-article-nav42 bodySmall">
                Crowdfunding
              </span>
              <a
                href="https://noctur.io/contact"
                className="intro-article-nav62 bodySmall"
              >
                Contact
              </a>
            </nav>
          </div>
          <div className="intro-article-container1">
            <nav className="intro-article-container2">
              <Link to="/about" className="intro-article-nav-ab bodySmall">
                About |
              </Link>
              <Link to="/blog" className="intro-article-nav-blog bodySmall">
                Blog |
              </Link>
              <span className="intro-article-nav-fund bodySmall">Funding</span>
              <Link
                to="/contact"
                className="intro-article-nav-contact bodySmall"
              >
                | Contact
              </Link>
            </nav>
            <div
              data-thq="thq-burger-menu"
              className="intro-article-burger-menu"
            ></div>
          </div>
          <div className="intro-article-container3">
            <div
              data-thq="thq-mobile-menu"
              className="intro-article-mobile-menu1 mobileMenu"
            ></div>
          </div>
        </header>
      </div>
      <div className="intro-article-hero">
        <div className="intro-article-hero1 heroContainer">
          <div className="intro-article-article">
            <div className="intro-article-profile">
              <div className="intro-article-profile1">
                <img
                  alt="profile"
                  src="/external/20220305_152758-200h.jpg"
                  loading="eager"
                  image_src="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDd8fG1hbiUyMHBvcnRyYWl0fGVufDB8fHx8MTYyNjQzMTMwMw&amp;ixlib=rb-1.2.1&amp;h=1200"
                  className="intro-article-image1"
                />
                <span className="intro-article-text">Cameron Emery, CEO</span>
              </div>
              <span className="intro-article-text01"> • Mar 14 • </span>
              <span className="intro-article-text02">10 min read</span>
            </div>
            <h1 className="intro-article-text03">
              <span className="intro-article-text04">Introducing Nocturio</span>
              <br></br>
            </h1>
            <h1 className="intro-article-text06">&quot;Nocturne + .io&quot;</h1>
            <span className="intro-article-text07">
              <span className="intro-article-text08">
                Welcome to our inaugural blog post! We&apos;re thrilled to
                embark on this exciting journey with you and share insights into
                the development process of Nocturio. As we navigate through the
                stages of building and refining our platform, we invite you to
                join us every step of the way.
              </span>
              <br className="intro-article-text09"></br>
              <br className="intro-article-text10"></br>
              <span className="intro-article-text11">About Us</span>
              <br className="intro-article-text12"></br>
              <br className="intro-article-text13"></br>
              <span className="intro-article-text14">
                At Nocturio, our mission is to empower creators and inspire
                readers by providing a vibrant platform for diverse content.
                Whether you&apos;re a writer, illustrator, voice-over artist, or
                simply an avid reader, we strive to create an inclusive space
                where creativity thrives and stories come to life. Our team is
                passionate about fostering community engagement and supporting
                creators as they share their unique voices with the world.
              </span>
              <br className="intro-article-text15"></br>
              <br className="intro-article-text16"></br>
              <span className="intro-article-text17">
                Supporting Our Journey
              </span>
              <br className="intro-article-text18"></br>
              <br className="intro-article-text19"></br>
              <span className="intro-article-text20">
                As we embark on this journey, your support means the world to
                us. Here are a few ways you can get involved and help us along
                the way:
              </span>
              <br className="intro-article-text21"></br>
              <br className="intro-article-text22"></br>
              <span className="intro-article-text23">1. Stay Connected:</span>
              <span className="intro-article-text24">
                {' '}
                Follow us on our social media channels and subscribe to our
                newsletter for the latest updates, behind-the-scenes insights,
                and exclusive content.
              </span>
              <br className="intro-article-text25"></br>
              <br className="intro-article-text26"></br>
              <span className="intro-article-text27">2. Spread the Word:</span>
              <span className="intro-article-text28">
                {' '}
                Share our platform with your friends, family, and fellow
                enthusiasts. Every mention helps us reach new audiences and grow
                our community.
              </span>
              <br className="intro-article-text29"></br>
              <br className="intro-article-text30"></br>
              <span className="intro-article-text31">3. Provide Feedback:</span>
              <span className="intro-article-text32">
                {' '}
                We value your input! Whether it&apos;s a suggestion, question,
                or comment, we welcome your feedback as we strive to improve and
                evolve.
              </span>
              <br className="intro-article-text33"></br>
              <br className="intro-article-text34"></br>
              <span className="intro-article-text35">
                4. Join the Conversation:
              </span>
              <span className="intro-article-text36">
                {' '}
                Engage with us on social media, join our forums, and participate
                in discussions. Your insights and perspectives enrich our
                community and shape the future of Nocturio.
              </span>
              <br className="intro-article-text37"></br>
              <br className="intro-article-text38"></br>
              <span className="intro-article-text39">
                Insights into Development
              </span>
              <br className="intro-article-text40"></br>
              <br className="intro-article-text41"></br>
              <span className="intro-article-text42">
                In our blog posts, we&apos;ll be lifting the curtain on the
                development process of Nocturio. From the initial concept to the
                final product, we&apos;ll share our experiences, challenges, and
                triumphs along the way. Whether you&apos;re curious about the
                technical aspects of app development or eager to learn about our
                content curation strategies, we&apos;ve got you covered.
              </span>
              <br className="intro-article-text43"></br>
              <br className="intro-article-text44"></br>
              <span className="intro-article-text45">Get in Touch</span>
              <br className="intro-article-text46"></br>
              <br className="intro-article-text47"></br>
              <span className="intro-article-text48">
                Have questions, press inquiries, or simply want to say hello?
                We&apos;d love to hear from you! Feel free to reach out to us
                via email at contact@noctur.io or connect with us on social
                media. Your support and engagement are invaluable to us, and
                we&apos;re excited to connect with you.
              </span>
              <br className="intro-article-text49"></br>
              <br className="intro-article-text50"></br>
              <span className="intro-article-text51">
                Legal Documents and Press Kit
              </span>
              <br className="intro-article-text52"></br>
              <br className="intro-article-text53"></br>
              <span className="intro-article-text54">
                As promised, we&apos;ve included the first revision of our
                privacy policy and terms and conditions, along with our press
                kit, at the end of this post. Transparency and integrity are
                core values at Nocturio, and we&apos;re committed to upholding
                the highest standards in all our endeavors.
              </span>
              <br className="intro-article-text55"></br>
              <br className="intro-article-text56"></br>
              <br className="intro-article-text57"></br>
              <span className="intro-article-text58">
                Thank you for joining us on this incredible journey. Together,
                let&apos;s build a vibrant community where creativity knows no
                bounds. Stay tuned for more exciting updates, and remember, the
                best is yet to come!
              </span>
            </span>
            <a
              href="https://tinyurl.com/nocturio-terms-conditions"
              target="_blank"
              rel="noreferrer noopener"
              className="intro-article-link"
            >
              <span>Terms and Conditions</span>
              <br></br>
            </a>
            <a
              href="https://tinyurl.com/nocturio-privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
              className="intro-article-link1"
            >
              Privacy Policy
            </a>
            <a
              href="https://example.com"
              target="_blank"
              rel="noreferrer noopener"
              className="intro-article-link2"
            >
              Press Kit
            </a>
          </div>
        </div>
      </div>
      <div className="intro-article-footer">
        <footer className="intro-article-footer1 footerContainer">
          <div className="intro-article-container4">
            <div className="intro-article-container5">
              <span className="intro-article-logo2">Nocturio</span>
              <img
                alt="image"
                src="/external/fiendsyneye_2-200w.png"
                className="intro-article-image2"
              />
            </div>
            <nav className="intro-article-nav">
              <a
                href="https://noctur.io/about"
                target="_blank"
                rel="noreferrer noopener"
                className="intro-article-nav121 bodySmall"
              >
                About |
              </a>
              <a
                href="https://noctur.io/blog"
                target="_blank"
                rel="noreferrer noopener"
                className="intro-article-nav221 bodySmall"
              >
                Blog |
              </a>
              <span className="intro-article-nav421 bodySmall">Funding |</span>
              <a
                href="https://noctur.io/contact"
                target="_blank"
                rel="noreferrer noopener"
                className="intro-article-nav621 bodySmall"
              >
                Contact
              </a>
            </nav>
          </div>
          <div className="intro-article-separator"></div>
          <div className="intro-article-container6">
            <span className="intro-article-text61 bodySmall">
              © 2024 Nocturio, All Rights Reserved.
            </span>
            <div className="intro-article-icon-group">
              <a
                href="https://twitter.com/_nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="intro-article-link3"
              >
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="intro-article-icon socialIcons"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </a>
              <a
                href="https://www.instagram.com/_nocturio/"
                target="_blank"
                rel="noreferrer noopener"
                className="intro-article-link4"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="intro-article-icon2 socialIcons"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="https://www.facebook.com/nocturio"
                target="_blank"
                rel="noreferrer noopener"
                className="intro-article-link5"
              >
                <svg
                  viewBox="0 0 602.2582857142856 1024"
                  className="intro-article-icon4 socialIcons"
                >
                  <path d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"></path>
                </svg>
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default IntroArticle
